import { stringify } from 'query-string'
import API from '@axios'
import { apiHelper } from '@/helpers/apiHelper'

const API_URL = '/api/crm/crm_relationship'

export default {
  ...apiHelper(API_URL),

  update(id, payload, query) {
    return API.patch(`${API_URL}/${id}?${stringify(query)}`, payload)
  },

  autocomplete(connectingEntityType, connectingEntityId, query = {}) {
    return API.get(`${API_URL}/${connectingEntityType}/${connectingEntityId}/autocomplete?${stringify(query)}`)
  },

  link(id, payload) {
    return API.post(`${API_URL}/link/${id}`, payload)
  },
}
