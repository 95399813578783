import API from '@axios'

const API_URL = '/api/crm/tree'

export default {
  getFieldSelection(tableName) {
    return API.get(`${API_URL}/fields/${tableName}`)
  },
  resetFieldSelection(tableName) {
    return API.post(`${API_URL}/fields/${tableName}/reset`)
  },
  setFieldSelection(tableName, fieldSelectionRequest) {
    return API.post(`${API_URL}/fields/${tableName}`, fieldSelectionRequest)
  },

}
