import * as yup from 'yup'

export const crmRelationshipSchema = yup.object().shape({
  name: yup.string().nullable().required().max(50, 'Max 50 limit'),

  table: yup.string().nullable().required().max(50, 'Max 50 limit'),
})

export const crmRelationshipMappingSchema = yup.object().shape({
  entity1CrmRelationshipUuidKey: yup.string().nullable().required(),

  entity2CrmRelationshipUuidKey: yup.string().nullable().required(),

  entity2UuidKey: yup.string().nullable().required(),
})

export default {
  crmRelationshipSchema,
  crmRelationshipMappingSchema,
}
