var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"id":"page--forms","no-body":""}},[_c('div',[_c('CRMRelationshipForm',{attrs:{"type":_vm.type,"resource-id":_vm.resourceId,"visible":_vm.showForm},on:{"close":function($event){_vm.showForm = false},"saved":_vm.fetchList}}),_c('div',{staticClass:"m-2 d-flex justify-content-between table-actions"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-1"},[_c('b-button',{class:{
              'icon-disabled': !_vm.hasSystemSettingsActions,
            },attrs:{"id":`create-${_vm.type}-button`,"variant":"primary"},on:{"click":function($event){_vm.hasSystemSettingsActions && _vm.toggleModal()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('Create New')))])],1),_c('b-tooltip',{attrs:{"target":`create-${_vm.type}-button`}},[_vm._v(" "+_vm._s(_vm.hasSystemSettingsActions ? _vm.$t('Create') : _vm.$t('no-action-permission'))+" ")])],1)])]),_c('div',{staticClass:"m-2"},[_c('b-table',{ref:"refTable",staticClass:"table-responsive",attrs:{"busy":_vm.loading,"items":_vm.items,"fields":_vm.tableColumns,"responsive":"","primary-key":"id","show-empty":"","empty-text":_vm.$t('No matching records found')},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"head()",fn:function({ label }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(label)))])]}},{key:"cell(actions)",fn:function({ item }){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                'icon-disabled': !_vm.hasSystemSettingsActions,
              },attrs:{"id":`edit-row-${item.uuid}-icon`,"icon":"EditIcon","size":"16"},on:{"click":function($event){_vm.hasSystemSettingsActions && _vm.toggleModal(item.uuid)}}}),_c('b-tooltip',{attrs:{"placement":"left","title":_vm.hasSystemSettingsActions ? _vm.$t('Edit') : _vm.$t('no-action-permission'),"target":`edit-row-${item.uuid}-icon`}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
                'icon-disabled': !_vm.hasSystemSettingsActions,
              },attrs:{"id":`delete-row-${item.uuid}-icon`,"icon":"TrashIcon","size":"16"},on:{"click":function($event){_vm.hasSystemSettingsActions && _vm.confirmRemoval(item)}}}),_c('b-tooltip',{attrs:{"placement":"left","title":_vm.hasSystemSettingsActions ? _vm.$t('Delete') : _vm.$t('no-action-permission'),"target":`delete-row-${item.uuid}-icon`}})],1)]}}])})],1),(!_vm.loading)?_c('prozess-pagination',{staticClass:"mx-2 mb-2",class:{ 'd-none': !_vm.total },attrs:{"meta":_vm.dataMeta,"page":_vm.currentPage,"total":_vm.total,"size":_vm.perPage},on:{"change":_vm.handleCurrentPage}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }