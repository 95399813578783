<template>
  <div id="crm-relationships--index">
    <b-tabs
      class="tabs--inner"
      pills
      :value="tabs.indexOf(this.$route.params.tab)"
      @input="handleTabChange"
    >
      <b-tab :title="$t('Companies')" active>
        <b-card-text>
          <CRMRelationshipList :type="Entity.COMPANY" />
        </b-card-text>
      </b-tab>
      <b-tab :title="$t('Contacts')">
        <b-card-text>
          <CRMRelationshipList :type="Entity.CONTACT" />
        </b-card-text>
      </b-tab>
      <b-tab :title="$t('Company Tree')">
        <b-card-text>
          <CrmRelationshipTreeSettings />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { Entity } from '@/constants/app'
import CRMRelationshipList from '@/components/CRMRelationships/CRMRelationshipList.vue'
import CrmRelationshipTreeSettings from './CrmRelationshipTreeSettings.vue'

export default {
  name: 'CrmRelationshipsIndex',

  components: {
    CRMRelationshipList,
    CrmRelationshipTreeSettings,
  },

  data() {
    return {
      Entity,
      tabs: [Entity.COMPANY, Entity.CONTACT, 'tree'],
      defaultTab: Entity.COMPANY,
    }
  },

  computed: {
    activeTab() {
      return this.$route.params.tab || this.defaultTab
    },
  },

  methods: {
    handleTabChange(index) {
      this.$router.push({
        name: 'crm-relationship-settings',
        params: {
          tab: this.tabs[index] || this.defaultTab,
        },
      })
    },
  },
}
</script>
