<template>
  <div class="t3_entity-field-selector tw-mt-4">
    <div class="tw-mb-4 tw-flex tw-justify-between">
      <h4 class="mb-0 tw-text-body">{{ $t(entity.label) }}</h4>
      <span v-if="true" class="tw-cursor-pointer" @click="collapsed = !collapsed">
        <small> {{ collapsed ? $t('Hide') : $t('Show') }} </small>
        <feather-icon :icon="collapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'" class="tw-ml-2" />
      </span>
    </div>
    <div class="column__content tw-rounded-lg">
      <TreeFieldSelectControl :entity="entity" :collapsed="collapsed" :field-options="fieldOptions" />
    </div>
  </div>
</template>
  <script>
  import TreeFieldSelectControl from './TreeFieldSelectControl.vue'

  export default {
    components: {
        TreeFieldSelectControl,
    },
    props: {
      entity: {
        type: Object,
        required: true,
      },
      collapsed: {
        type: Boolean,
        required: true,
      },
      fieldOptions: {
        type: Object,
        required: true,
      },
    },
    mounted() {
    },
  }
  </script>

  <style lang="scss">
  @import '@/assets/scss/master-variables.scss';

  .pz__entity-field-selector {
    width: 100%;

    .column__content {
      background: var(--colour--card-bg);
    }

    .selectors__backdrop {
      inset: 0;
      background: rgba($colour--black-light, 0.2);
      z-index: 3;
    }
  }
  </style>
