<template>
  <b-card
    id="page--forms"
    no-body
  >
    <div class="tw-flex" style="padding:1.5em 1.5em 0em 1.5em">
      <div class="tw-mb-2 tw-flex" style="width: 50%;">
        <h2 class="mr-3">{{ $t('Company Relationship Tree') }}</h2>
      </div>
      <div class="tree-settings-actions tw-flex tw-justify-end tw-mb-2" style="width: 50%;">
        <b-button id="sel-reset-button" variant="outline-primary" class="mr-1" :disabled="this.loading===true" @click="resetFieldSelection">
          <span class="align-middle">{{ $t('Reset') }}</span>
        </b-button>
        <b-button id="sel-save-button" variant="primary" :disabled="this.loading===true" @click="saveFieldSelection">
          <span class="align-middle">{{ $t('Save') }}</span>
        </b-button>
      </div>
    </div>
    <div>
      <div class="m-2 d-flex justify-content-between table-actions">
        <div class="d-flex">
          <div style="display: flex; align-items: center; justify-content: center;">
            <b-spinner v-if="loading" variant="primary" />
          </div>
          <div v-if="!loading" class="mr-1">
            <TreeEntityFieldControl
              v-for="(item, i) in this.fieldOptions"
              :key="i"
              :entity="item"
              :field-options="fieldOptions"
            />
          </div>
        </div>
      </div>

    </div>
  </b-card>
</template>

<script>
import TreeFieldSelectControl from './TreeFieldSelectControl.vue'
import TreeEntityFieldControl from './TreeEntityFieldControl.vue'
import crmRelationshipTreeService from '@/services/crmRelationshipTree'

export default {
  components: {
    TreeFieldSelectControl,
    TreeEntityFieldControl,
  },

  props: {
  loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showForm: false,
      fieldOptions: [],
      baseFieldOptions: [],
    }
  },
  created() {
    this.getFieldSelection()
  },
  methods: {
    async getFieldSelection() {
      this.loading = true
      const { response } = await this.$async(crmRelationshipTreeService.getFieldSelection('company'))
      this.fieldOptions = response.data
      this.baseFieldOptions = JSON.parse(JSON.stringify(response.data))
      this.loading = false
    },
    async resetFieldSelection() {
      this.loading = true
      const { response } = await this.$async(crmRelationshipTreeService.resetFieldSelection('company'))
        if (response.status === 200) {
          this.getFieldSelection()
        }
        this.loading = false
    },
    async saveFieldSelection() {
        this.loading = true

        // todo: Check if this.baseFieldOptions and this.fieldOptions has difference before calling update
        const entitySelection = []
        Object.values(this.fieldOptions).forEach(entityObj => {
          const showColumns = Object.values(entityObj.columns).filter(object => object.showInTree).map(object => object.key)
          if (showColumns.length > 0) {
            entitySelection.push({
              crmType: 'company',
              entityKey: entityObj.key,
              entitySchema: entityObj.schema,
              entityType: entityObj.type,
              relationshipId: entityObj.relationshipId,
              columnKeys: showColumns,
            })
          }
        })

        const treeFieldSelectionRequest = { entity: entitySelection }
        const { response } = await this.$async(crmRelationshipTreeService.setFieldSelection('company', treeFieldSelectionRequest))
        if (response.status === 200) {
          this.getFieldSelection()
        }
        this.loading = false
    },
  },
}
</script>
