<template>
  <div class="advanced-search__option">
    <div v-show="collapsed" class="option__fields tw-py-2 tw-pl-6">
      <div
        v-for="(item, i) in entity.columns"
        :key="i"
        class="tw-flex tw-justify-between tw-items-center tw-mb-3"
      >
        <label
          class="tw-flex tw-items-center tw-text-xs tw-cursor-pointer"
          @click="handleOptionClick(item)"
        >
          <span>{{ $t(getItemDisplayName(item)) }}</span>
        </label>
        <span>
          <b-form-checkbox
            :checked="item.showInTree"
            switch
            inline
            class="tw-mx-0 filter__switch"
            @change="(e) => handleCheckboxChange(item, e)"
          />
        </span>
      </div>
    </div>
  </div>
</template>
  <script>
  import { EntityIcon } from '@/constants/app'

  export default {
    name: '',
    props: {
      entity: {
        type: Object,
        required: true,
      },
      collapsed: {
        type: Boolean,
        required: true,
      },
      fieldOptions: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        EntityIcon,
      }
    },
    mounted() {
    },
    methods: {
      handleOptionClick(field) {
        console.log('handleOptionClick', field)
      },
      handleCheckboxChange(field) {
        const entityKey = this.entity.metaKey
        this.fieldOptions[entityKey].columns[field.key].showInTree = !this.fieldOptions[entityKey].columns[field.key].showInTree
      },
      getItemDisplayName(item) {
        return item.label
      },
      isSelected(key) {
        return this.selections.includes(key)
      },
      selections() {
        return this.fieldOptions
          .filter(({ selected }) => selected)
          .map(({ key }) => key)
      },
    },
  }
  </script>
  <style lang="scss">
  .advanced-search__option {
    .filter__switch {
      &.switch--disabled {
        .custom-control-label:before {
          cursor: default;
        }

        &.switch--on {
          .custom-control-label:before {
            background-color: var(--colour--primary);
          }
        }
      }
    }
  }
</style>
