<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="!!resourceId"
    :form-title="`${$t(titleAction)} ${$t('CRM Relationship')}`"
    :loading="loading"
    :saving="saving"
    @close="close"
    @submit="save"
  >
    <form
      :id="formId"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <prozess-input
        v-model="resource.name"
        :placeholder="$t('Relationship')"
        icon="GitPullRequestIcon"
        field="name"
        name="name"
        :error="$hasError('name')"
        :hint="`*${$t('Required')}`"
        @enter="save"
      />
      <b-form-checkbox
          v-model="resource.treeVisible"
          class="tw-mb-4"
        >
          {{ $t('Must be visible in the organizational tree') }}
      </b-form-checkbox>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import { crmRelationshipSchema } from '@/schema/crmRelationship'
import crmRelationshipService from '@/services/crmRelationship'

const defaultResource = {
  name: null,
  treeVisible: true,
  table: null,
}

export default {
  name: 'CRMRelationshipForm',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },

    type: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      formId: 'crmRelationshipForm',
      saving: false,
      loading: true,
      errors: [],
      resource: this.$lodash.cloneDeep(defaultResource),
    }
  },

  computed: {
    titleAction() {
      return this.resourceId ? 'Edit' : 'Add New'
    },
  },

  watch: {
    visible: {
      handler(visible) {
        if (visible) {
          this.reset()

          if (this.resourceId) {
            this.getData()
          }
        }
      },

      immediate: true,
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    async getData() {
      this.loading = true

      const { response } = await this.$async(
        crmRelationshipService.getOne(this.resourceId),
      )

      const { data } = response
      this.resource = {
        ...data,
        name: data.name,
      }

      this.loading = false
    },

    reset() {
      this.errors = []
      this.resource = this.$lodash.cloneDeep(defaultResource)
      this.loading = false
      this.saving = false
    },

    async save() {
      this.saving = true

      this.resource.table = this.type

      this.errors = await this.yupValidate(
        crmRelationshipSchema,
        this.resource,
      )

      if (this.errors.length) {
        this.saving = false
        return
      }

      const { version } = this.resource

      const request = this.resourceId
        ? crmRelationshipService.update(this.resourceId, this.resource, {
          version,
        })
        : crmRelationshipService.store(this.resource)

      await this.$async(request)

      this.saving = false

      this.$emit('saved')
      this.close()
    },
  },
}
</script>
